import { css } from 'styled-components';

export * from './common.css';
import { media } from 'src/system2';

export const section = css`
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
  --bg-color: #fcfdfe;
`;

export const sectionHeading = css`
  text-align: center;
  font-size: var(--fz);
  line-height: calc(var(--fz) + 10px);
  --fz: 24px;
  @media (min-width: 24em) {
    --fz: 28px;
  }
  @media (min-width: 36em) {
    --fz: 32px;
  }
  @media (min-width: 48em) {
    --fz: 36px;
  }
  @media (min-width: 60em) {
    --fz: 40px;
  }
`;

export const frame = css`
  --bleed: 80px;
  margin-left: calc(var(--bleed) / -1);
  margin-right: calc(var(--bleed) / -1);
  width: calc(100% + 2 * var(--bleed));
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
  position: relative;
  &::before,
  &::after {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: var(--bleed);
    height: 100%;
    background-color: pink;
    z-index: 1;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, var(--bg-color), transparent);
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, var(--bg-color), transparent);
  }
`;

export const slide = css`
  margin: 0;
  width: calc(100% - var(--outer-x));
  background-color: #FFFFFF;
  width: 75vw;
  position: relative;
  box-shadow: 0.1rem 0.1rem 0.6rem #00000030;
  border-radius: 25px;
  padding: 50px 30px;
  margin-left: 20px !important;
  ${media(
    's',
    css`
      width: 60vw;
    `,
  )}
  ${media(
    'm',
    css`
      padding: 60px 40px;
    `,
  )}
  ${media(
    'l',
    css`
      width: 45rem;
      padding: 80px 60px;
    `,
  )}
  display: flex;
`;

export const quote = css`
  position: absolute;
  width: 135px;
  height: 108px;
  bottom: -40px;
  right: 50px;
`;

export const quoteText = css`
  & > p::before {
    content: '\\201c';
  }
  & > p::after {
    content: '\\201d';
  }
  color: #5f656e;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const captionText = css`
  font-size: 16px;
  color: black;
  display: flex;
  align-items: center;
`;

export const pattern = css`
  position: absolute;
  left: -40px;
  bottom: -40px;
  z-index: -1;
`;

export const stop = css`
  padding: 0.05px;
  display: flex;
`;

export const flowGrid = css`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: center;
  justify-content: center;
  margin: -15px;
  & > * {
    padding: 15px;
  }
  ${media(
    'ml',
    css`
      align-items: center;
      margin: -20px;
      & > * {
        padding: 20px;
      }
    `,
  )}
  ${media(
    'l',
    css`
      justify-content: start;
      align-items: center;
      margin: -25px;
      & > * {
        padding: 25px;
      }
    `,
  )}
`;

export const avatar = css`
  margin-top: -15px;
  margin-right: 1rem;
  ${media(
    'ml',
    css`
      // width: 180px;
    `,
  )}
  ${media(
    'l',
    css`
      // width: 220px;
    `,
  )}
`;

export const contentStack = css`
  flex: 0 0 100%;
  ${media(
    'ml',
    css`
      flex: 1 1 0%;
    `,
  )}
`;
