/* eslint-disable react/display-name */
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';
// import loadable from '@loadable/component';

import { FluidImg } from 'src/embeds/image/image-dato';
import { Stack } from 'src/system2/stack';
import { Carousel } from 'src/primitives/carousel';
// import { useBreakpoints } from 'src/hooks/use-breakpoints';
import * as sys from '../../system';
import * as css from './drawer-testimonials-v2.css';

const Avatar = styled.div`
  ${css.avatar}
`;
const Content = styled(Stack)`
  ${css.contentStack}
`;

const TestSlide = React.forwardRef(function TestSlide(
  {
    quoteTextNode,
    personName,
    personTitle,
    personImage,
    customer,
    motionValues: { slidePosition, slideProgress, carouselProgress, carouselVelocity },
  },
  fwdRef,
) {

  return (
    <figure css={css.slide} ref={fwdRef}>
      <div css={css.stop}>
        <div css={css.flowGrid}>
          <Content sx={{ gapY: { _: 30 } }}>
            <blockquote
              css={css.quoteText}
              cite={customer && customer.url}
              dangerouslySetInnerHTML={{ __html: quoteTextNode.childMarkdownRemark.html }}
            />
            <div css={css.captionText}>
              {personImage &&
                <div style={{ flex: '0 0 auto' }}>
                  <Avatar>
                    <FluidImg data={personImage} maxWidth={100} />
                  </Avatar>
                </div>}
              <div>
                <strong>{personName}</strong>
                <br />
                {personTitle}
                {customer ? (
                  <span>
                    {' @ '}
                    <cite>{customer.name}</cite>
                  </span>
                ) : null}
              </div>
            </div>
          </Content>
        </div>
      </div>
    </figure>
  );
});

export default function Testimonials({ callouts, content, contentNode, customData, heading }) {
  const quotes = callouts.filter(({ __typename }) => __typename === 'DatoCmsTestimonialBlock');
  customData = JSON.parse(customData);

  return (
    <section css={[css.section]} className="reset2">
      <div css={[sys.wrapLast, sys.frame]}>
        {heading && <h2 css={[css.sectionHeading]}>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        <div css={[css.frame]}>
          <Carousel
            matchMedia="<50em"
            flickityOptions={{
              pageDots: false,
              wrapAround: true,
              prevNextButtons: customData?.flags.prevNextButtons
            }}
          >
            {quotes.map((quote) => (
              <TestSlide key={quote.personName} {...quote} />
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
